import {
  DashboardOutlined,
  LineChartOutlined,
  AuditOutlined,
  HomeOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'home',
    path: '/app/home',
    title: 'home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: '/app/deal/',
        title: 'Deals',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'property-list',
        path: '/app/property-list',
        title: 'Properties',
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'myorders',
        path: '/app/myorders',
        title: 'Orders',
        icon: AuditOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
