import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match, history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/auth');
    }
  }, [history, userInfo]);
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route
          path={`${match.url}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${match.url}/property-list`}
          component={lazy(() => import(`./property-list`))}
          exact
        />
        <Route
          path={`${match.url}/property-list/:id`}
          component={lazy(() => import(`./property-screen`))}
        />
        <Route
          path={`${match.url}/deal/:id?`}
          component={lazy(() => import(`./deal-screen`))}
        />
        <Route
          path={`${match.url}/personal-details`}
          component={lazy(() => import(`./personal-details-screen`))}
        />
        <Route
          path={`${match.url}/payment`}
          component={lazy(() => import(`./payment-screen`))}
        />
        <Route
          path={`${match.url}/deal-sign/`}
          component={lazy(() => import(`./deal-sign`))}
        />
        <Route
          path={`${match.url}/order/:id`}
          component={lazy(() => import(`./order-screen`))}
        />
        <Route
          path={`${match.url}/myorders`}
          component={lazy(() => import(`./my-orders-screen`))}
        />
        <Redirect from={`${match.url}`} to={`${match.url}/deal`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
