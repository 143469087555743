import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from '../shared-components/Loading';
import { Menu, Dropdown, Avatar, Alert } from 'antd';
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { logout } from 'redux/actions/userActions';

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    disabled: true,
    // path: '/',
  },

  {
    title: 'Account Setting',
    icon: SettingOutlined,
    // path: '/',
  },
  {
    title: 'Billing',
    icon: ShopOutlined,
    // path: '/',
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    // path: '/',
  },
];

export const NavProfile = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  let history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push('/auth/');
    }
  }, [history, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const profileImg = '/img/thumbs/profile.png';
  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <div className='d-flex'>
          <Avatar size={45} src={profileImg} />
          <div className='pl-3'>
            {!userInfo ? (
              <Alert type='error' message={error} />
            ) : (
              <h4 className='mb-0'>{userInfo.name}</h4>
            )}

            <span className='text-muted'>Admin User</span>
          </div>
        </div>
      </div>
      <div className='nav-profile-body'>
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className='mr-3' type={el.icon} />
                  <span className='font-weight-normal'>{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.legth + 1} onClick={logoutHandler}>
            <span>
              <LogoutOutlined className='mr-3' />
              <span className='font-weight-normal'>Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Alert type='error' message={error} />
      ) : (
        <Dropdown
          placement='bottomRight'
          overlay={profileMenu}
          trigger={['click']}
        >
          <Menu className='d-flex align-item-center' mode='horizontal'>
            <Menu.Item>
              <Avatar src={profileImg} />
            </Menu.Item>
          </Menu>
        </Dropdown>
      )}
    </>
  );
};

export default NavProfile;
