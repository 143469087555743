import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import {
  propertyListReducer,
  propertyDetailsReducer,
} from './propertyReducers';
import { dealReducer } from './dealReducers';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderMyListReducer,
} from './orderReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './/userReducers';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  propertyList: propertyListReducer,
  propertyDetails: propertyDetailsReducer,
  deal: dealReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderMyList: orderMyListReducer,
});

export default reducers;
