import {
  DEAL_ADD_PROPERTY,
  DEAL_REMOVE_PROPERTY,
  DEAL_SAVE_PERSONAL_DETAILS,
  DEAL_SAVE_PAYMENT_METHOD,
  DEAL_CLEAR_ITEMS,
} from '../constants/dealConstants';

export const dealReducer = (
  state = { dealItems: [], personalDetails: {} },
  action
) => {
  switch (action.type) {
    case DEAL_ADD_PROPERTY:
      const dealItem = action.payload;

      const existDealItem = state.dealItems.find(
        (x) => x.property === dealItem.property
      );

      if (existDealItem) {
        return {
          ...state,
          dealItems: state.dealItems.map((x) =>
            x.property === existDealItem.property ? dealItem : x
          ),
        };
      } else {
        return {
          ...state,
          dealItems: [...state.dealItems, dealItem],
        };
      }
    case DEAL_REMOVE_PROPERTY:
      return {
        ...state,
        dealItems: state.dealItems.filter((x) => x.property !== action.payload),
      };
    case DEAL_SAVE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: action.payload,
      };
    case DEAL_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case DEAL_CLEAR_ITEMS:
      return {
        ...state,
        dealItems: [],
      };
    default:
      return state;
  }
};
