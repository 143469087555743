import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';

export const Views = (props) => {
  const { locale } = props;
  const currentAppLocale = AppLocale[locale];
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/app' />
          </Route>
          <Route path='/auth'>
            <AuthLayout />
          </Route>
          <Route path='/app'>
            <AppLayout />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
